import React, { useContext } from "react";
import DELETE_CATEGORY from "./DELETE_CATEGORY";
import EDIT_CATEGORY from "./EDIT_CATEGORY";
import { AuthContext } from "../../context/authContext";

import logo from "../../images/logo.png";

export default function Sidemenu(props) {
  const [auth] = useContext(AuthContext);

  return (
    <>
      <div
        className={props.open ? "side-menu bigger open" : "side-menu bigger"}
      >
        <div className="smLogoCont">
          <img className="smLogo" src={logo} alt="logo" />
        </div>

        <div className="smLinks">
          {props.data.map((obj) => {
            return auth.editMode ? (
              <div key={obj._id} className="row-2">
                <EDIT_CATEGORY title={obj.title} id={obj._id} field="title" />

                <DELETE_CATEGORY id={obj._id} />
              </div>
            ) : (
              <a href={"/category/" + obj._id} key={obj._id}>
                {obj.title}
              </a>
            );
          })}
          <p
            onClick={() => {
              props.changeActive(1);
              props.changeOpen(false);
            }}
            className=" color_module1"
          >
            Modul 1
          </p>
          <p
            onClick={() => {
              props.changeActive(2);
              props.changeOpen(false);
            }}
            className="  color_module2"
          >
            Modul 2
          </p>
          <p
            onClick={() => {
              props.changeActive(3);
              props.changeOpen(false);
            }}
            className="  color_module3"
          >
            Modul 3
          </p>
          <p
            className="mobileHide fullView"
            onClick={() => {
              if (props.active !== -1) {
                props.changeActive(-1);
                props.changeOpen(false);
              } else {
                props.changeActive(1);
                props.changeOpen(false);
              }
            }}
          >
            Överblick
          </p>
          <label
            className="mobileShow closeMenu"
            onClick={() => {
              props.changeOpen(false);
            }}
          >
            X
          </label>
        </div>
      </div>
      <div
        onClick={() => {
          props.changeOpen(true);
        }}
        className={
          props.open
            ? "hamburger mobileShow mobileHide"
            : "hamburger mobileShow"
        }
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}

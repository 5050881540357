import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/authContext";

import CatStore from "../context/catCTX";
import SubcatStore from "../context/subCTX";
import HiddenStore from "../context/hiddenCTX";

import Login from "./Login/Login";
import Mainpage from "./Categories/Mainpage";
import Subcategory from "./Subcategory/Subcategory";
import Controls from "./Controls";
import Hidden from "./Subcategory/Objects/TabPost/POST/Hidden";

import Frontpage from "./Fontpage/Frontpage";
import FrontpageEdit from "./Fontpage/FrontpageEdit";

import {BrowserRouter as Router, Route} from "react-router-dom";

function RouterHandler() {
  const [state, change] = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState("");

  //Sign user out after 1 hour
  useEffect(() => {
    setTimeout(() => {
      change.logout();
    }, 3600000);
  }, [state.token, change]);

  return state.token ? (
    <Router>
      <Route
        path="/"
        exact={true}
        render={() => {
          return (
            <CatStore>
              <Controls frontpage />
              <Mainpage />
            </CatStore>
          );
        }}
      />
      <Route
        exact={true}
        path="/category/:id"
        render={url => {
          return (
            <SubcatStore id={url.match.params.id}>
              <Controls />
              <Subcategory />
            </SubcatStore>
          );
        }}
      />

      <Route
        exact={true}
        path="/category/exe/:id"
        render={url => {
          return (
            <SubcatStore id={url.match.params.id}>
              <Controls />

              <Subcategory exe />
            </SubcatStore>
          );
        }}
      />
      <Route
        exact={true}
        path="/hidden/:id/:callbackID"
        render={url => {
          return (
            <HiddenStore id={url.match.params.id}>
              <Controls />

              <Hidden
                id={url.match.params.callbackID}
                backID={"/category/" + url.match.params.callbackID}
              />
            </HiddenStore>
          );
        }}
      />
      <Route
        exact={true}
        path="/fEdit"
        render={url => {
          return state.type === "admin" ? <FrontpageEdit /> : "Forbidden";
        }}
      />
    </Router>
  ) : isLogin ? (
    <Login
      isNotLogin={() => {
        setIsLogin(false);
      }}
    />
  ) : (
    <Frontpage
      isLogin={() => {
        setIsLogin(true);
      }}
    />
  );
}

export default RouterHandler;

import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();

const url = process.env.REACT_APP_URL + "upload";

export default async function API_SEND_FILE(
  file,
  fileName,
  isPublicFolder = false
) {
  const config = {
    headers: {
      authorization: cookie.get("token"),
      folder: isPublicFolder ? "public" : ""
    }
  };
  const fd = new FormData();
  const fName =
    fileName +
    "." +
    file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);
  fd.append("file", file, fName);

  return await axios.post(url, fd, config).catch(err => {
    alert("Error. File name taken.");
    return false;
  });
}

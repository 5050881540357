import React, {useState} from "react";
import "./styles/aboutSteps.css";
import Editor from "../Subcategory/Objects/Editor";
import API_EDIT_FRONTPAGE from "./API_EDIT_FRONTPAGE";

export default function AboutSteps(props) {
  const [module1, setModule1] = useState(undefined);
  const [module2, setModule2] = useState(undefined);
  const [module3, setModule3] = useState(undefined);

  async function send() {
    let data = {};
    if (module1) data.module1 = module1;
    if (module2) data.module2 = module2;
    if (module3) data.module3 = module3;
    await API_EDIT_FRONTPAGE(props.id, data);
    if (window !== undefined) {
      window.location.href = "/fEdit";
    }
  }

  return props.edit ? (
    <div className="aboutSiteEdit container">
      <h1>3</h1>
      <Editor
        onChange={val => {
          setModule1(val);
        }}
        value={props.module1}
      />
      <Editor
        onChange={val => {
          setModule2(val);
        }}
        value={props.module2}
      />
      <Editor
        onChange={val => {
          setModule3(val);
        }}
        value={props.module3}
      />
      <button
        onClick={() => {
          send();
        }}
      >
        Send
      </button>
    </div>
  ) : (
    <div className="aboutSteps">
      <div className="orangeBG">
        <p className="title paddingYSmall">Modul 1</p>
        <p
          className="paddingXSmall"
          dangerouslySetInnerHTML={{__html: props.module1}}
        />
      </div>
      <div className="pinkBG">
        <p className="title">Modul 2</p>
        <p dangerouslySetInnerHTML={{__html: props.module2}} />
      </div>
      <div className="blueBG">
        <p className="title">Modul 3</p>
        <p dangerouslySetInnerHTML={{__html: props.module3}} />
      </div>
    </div>
  );
}

import React from "react";
import "./Alert.css";

export default function Alert(props) {
  return (
    <div
      className="custom-container"

    >
      <div
        onClick={() => {
     props.close()
        }}
        className="custom-alert"
      >
        <h1>Varning!</h1>
        <p>{props.message}</p>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { Player, ControlBar, PlaybackRateMenuButton } from "video-react";
import image from "../../../../images/thumbnail.png";

const url = process.env.REACT_APP_URL;

export default class Video extends Component {
  componentDidMount() {
    this.player.playbackRate = 1;
    this.forceUpdate();
  }

  render() {
    return (
      <Player
        ref={c => {
          this.player = c;
        }}
        poster={image}
        playsInline
        src={url + "file/" + this.props.url}
      >
        <ControlBar>
          <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.75, 0.5, 0.25]} />
        </ControlBar>
      </Player>
    );
  }
}

import React, { useContext } from "react";
import { subCTX } from "../../context/subCTX";
import { AuthContext } from "../../context/authContext";

import API_SUB from "../../API/API_SUB";
import REMOVE_OBJ from "./Objects/REMOVE_OBJ";
import "./subcategory.css";
import Object from "./Objects/Object";
import Sidemenu from "./Sidemenu/Sidemenu";

function compare(a, b) {
  if (a.type < b.type) {
    return 1;
  }
  if (a.type < b.type) {
    return -1;
  }
  return 0;
}

function order(objs) {
  objs.sort(compare);
}

function Subcategory(props) {
  const [data, dispatch] = useContext(subCTX);
  // eslint-disable-next-line no-unused-vars
  const [auth, change] = useContext(AuthContext);

  if (props.exe) {
    order(data.obj);
  }

  return (
    <div>
      <Sidemenu id={data.id} activeStep={data.step} />

      <div className="objects">
        {data.obj.map((obj) => {
          return (
            <div className="object" key={obj._id}>
              <Object editMode={auth.editMode} data={obj} id={obj._id} />
              {auth.editMode ? <REMOVE_OBJ id={obj._id} /> : ""}
            </div>
          );
        })}
        {auth.editMode ? (
          <button
            className="add-object"
            onClick={async () => {
              const res = await API_SUB.add({ subcatID: data.id }, "obj");
              if (res) {
                dispatch({
                  type: "add",
                  objType: "obj",
                  payload: res,
                });
              }
            }}
          >
            Add Object
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Subcategory;

import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const url = process.env.REACT_APP_URL;

export default {
  get: async lang => {
    return await axios
      .post(
        url + "query",
        {
          type: "cat",
          lang: cookies.get("lang")
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {});
  },
  add: async obj => {
    return await axios
      .post(
        url + "add",
        {
          type: "cat",
          lang: cookies.get("lang"),
          data: obj
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code: 3");
      });
  },
  remove: async id => {
    return await axios
      .post(
        url + "remove",
        {
          type: "cat",
          lang: cookies.get("lang"),
          id: id
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code: 4");
      });
  },
  edit: async (id, params) => {
    return await axios
      .post(
        url + "edit",
        {
          type: "cat",
          lang: cookies.get("lang"),
          id: id,
          payload: params
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code: 5");
      });
  }
};

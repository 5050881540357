import React, { useContext, useState } from "react";
import { CategoryContext } from "../../context/catCTX";
import { AuthContext } from "../../context/authContext";
import "./category.scss";
import ADD_CATEGORY from "./ADD_CATEGORY";

import Sidemenu from "./Sidemenu";
import Step from "./Step";

function Mainpage() {
  const [data, dispatch] = useContext(CategoryContext);
  const [authState, changeStatus] = useContext(AuthContext);
  const [open, setOpen] = useState(true);

  function changeActiveStep(index) {
    dispatch({
      type: "activeStep",
      payload: index,
    });
  }

  return (
    <div className="main-page color_theme font-white">
      <Sidemenu
        active={data.activeStep}
        open={open}
        changeOpen={(val) => {
          setOpen(val);
        }}
        data={data.cats.filter((obj) => obj.type === 0)}
        changeActive={(step) => {
          changeActiveStep(step);
        }}
      />
      <div
        className={
          data.activeStep === -1 ? "step-container" : "step-container flex"
        }
      >
        <Step
          active={data.activeStep}
          step={1}
          data={data.cats.filter((obj) => obj.type !== 0 && obj.step === 1)}
        />
        <Step
          active={data.activeStep}
          step={2}
          data={data.cats.filter((obj) => obj.type !== 0 && obj.step === 2)}
        />
        <Step
          active={data.activeStep}
          step={3}
          data={data.cats.filter((obj) => obj.type !== 0 && obj.step === 3)}
        />
      </div>

      {authState.editMode ? <ADD_CATEGORY /> : ""}
    </div>
  );
}

export default Mainpage;

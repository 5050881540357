import React, {Component} from "react";
import RichTextEditor from "react-rte";

class MyStatefulEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
        ? RichTextEditor.createValueFromString(props.value, "html")
        : RichTextEditor.createEmptyValue()
    };
  }

  onSubmit = e => {
    this.props.onSubmit(this.state.value.toString("html"));
  };

  onChange = value => {
    this.setState({value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    return (
      <div className="editor">
        {this.props.editMode ? (
          <RichTextEditor value={this.state.value} onChange={this.onChange} />
        ) : (
          <RichTextEditor value={this.state.value} onChange={this.onChange} />
        )}
        {this.props.editMode ? (
          <button className="save-post" onClick={this.onSubmit}>
            Save post
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MyStatefulEditor;

import React, { createContext, useContext, useEffect, useReducer } from "react";
import API_SUB from "../API/API_SUB";
import { AuthContext } from "../context/authContext";

export const subCTX = createContext({});

function reducer(state, action) {
  switch (action.type) {
    case "add":
      switch (action.objType) {
        case "obj":
          return { ...state, obj: [...state.obj, action.payload] };
        default:
          return { ...state };
      }

    case "remove":
      switch (action.objType) {
        case "obj":
          const newObjs = state.obj.filter((obj) => {
            if (obj._id === action.payload) {
              return false;
            } else {
              return true;
            }
          });
          return { ...state, obj: newObjs };

        default:
          return { ...state };
      }

    case "edit":
      const c = state.obj.map((obj) => {
        if (obj._id === action.payload._id) {
          return action.payload;
        } else {
          return obj;
        }
      });

      return { ...state, obj: c };

    case "dataReady":
      return { id: action.id, obj: action.payload, step: action.step };

    default:
      return { ...state };
  }
}

const initialState = { obj: [], id: undefined };

const Store = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // eslint-disable-next-line no-unused-vars
  const [auth, change] = useContext(AuthContext);
  useEffect(() => {
    async function getCats() {
      const data = await API_SUB.get(props.id);

      const step = data.filter((obj) => obj.type == -1);

      if (data) {
        dispatch({
          type: "dataReady",
          payload: data,
          id: props.id,
          step: step[0].step,
        });
      } else {
        change.logout();
      }
    }
    getCats();
  }, [props.id, change]);

  return (
    <subCTX.Provider value={[state, dispatch]}>
      {props.children}
    </subCTX.Provider>
  );
};

export default Store;

import React, { useContext } from "react";
import { videoCTX } from "../../../../context/videoCTX";
import VideoPlayer from "./VideoPlayer";
import "./video.css";

export default function MultiVideo(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(videoCTX);

  return data.videos[data.activeVideo] ? (
    <div className="video-p">
      <div>
        <p
          className="videoTitle"
          dangerouslySetInnerHTML={{
            __html: data.videos[data.activeVideo].title
          }}
        ></p>

        {data.videos[data.activeVideo].video ? (
          <VideoPlayer url={data.videos[data.activeVideo].video} />
        ) : (
          ""
        )}
      </div>

      <nav className="selectVid">
        <i
          className="fa fa-chevron-circle-left"
          onClick={() => {
            dispatch({ type: "edit", objType: "activeVid", payload: -1 });
          }}
        />
        <div className="activeIcon">
          {data.videos.map(vid => {
            if (data.videos.indexOf(vid) === data.activeVideo) {
              return <label key={vid}>•</label>;
            } else {
              return (
                <label key={vid._id} className="gray">
                  •
                </label>
              );
            }
          })}
        </div>
        <i
          className="fa fa-chevron-circle-right"
          onClick={() => {
            dispatch({ type: "edit", objType: "activeVid", payload: 1 });
          }}
        />
      </nav>
      <p
        dangerouslySetInnerHTML={{ __html: data.videos[data.activeVideo].text }}
      ></p>
    </div>
  ) : (
    ""
  );
}

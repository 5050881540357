import React, {useState} from "react";
import "./styles/aboutCreators.scss";
import API_EDIT_FRONTPAGE from "./API_EDIT_FRONTPAGE";
import API_SEND_FILE from "./API_SEND_FILE";
import API_SUB from "../../API/API_SUB";
import Editor from "../Subcategory/Objects/Editor";

import noImage from "../../images/unavailable.png";

export default function AboutCreators(props) {
  const [name, setName] = useState(undefined);
  const [text, setText] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);

  const [name2, setName2] = useState(undefined);
  const [text2, setText2] = useState(undefined);
  const [file2, setFile2] = useState(undefined);
  const [fileName2, setFileName2] = useState(undefined);

  return props.edit ? (
    <div className="aboutCreators container">
      <h1>4</h1>
      <div className="personCont">
        <div className="personOBJ">
          <input
            onChange={e => {
              setName(e.target.value);
            }}
            placeholder={props.name}
          />
          <div>
            {props.image1 ? (
              <>
                <img
                  alt="mart"
                  src={process.env.REACT_APP_URL + "public/" + props.image1}
                />
                <button
                  onClick={async () => {
                    await API_EDIT_FRONTPAGE(props.id, {image1: ""});
                    API_SUB.remove("", "profilePic", "", props.image1);
                    if (typeof window !== undefined) {
                      window.location.href = "/fEdit";
                    }
                  }}
                >
                  Remove image
                </button>
              </>
            ) : (
              <>
                <label htmlFor={"file-upload1"} className="custom-file-upload">
                  {file ? "Set file name: " : "Upload Image"}
                </label>
                <input
                  className="hidden"
                  accept="image/png, image/jpeg"
                  id={"file-upload1"}
                  onChange={event => {
                    setFile(event.target.files[0]);
                  }}
                  type="file"
                />
                {file ? (
                  <>
                    <input
                      placeholder="File name"
                      onChange={e => {
                        setFileName(e.target.value);
                      }}
                    />
                    {file && fileName ? (
                      <button
                        onClick={async () => {
                          const r = await API_SEND_FILE(file, fileName, true);
                          if (r.data === true) {
                            const fName =
                              fileName +
                              "." +
                              file.name.substring(
                                file.name.lastIndexOf(".") + 1,
                                file.name.length
                              );
                            await API_EDIT_FRONTPAGE(props.id, {
                              image1: fName
                            });
                            if (typeof window !== undefined) {
                              window.location.href = "/fEdit";
                            }
                          }
                        }}
                      >
                        Uplaod
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            <Editor
              onChange={val => {
                setText(val);
              }}
              value={props.nameText}
            />
            <button
              onClick={async () => {
                const data = {};
                if (text) data.nameText = text;
                if (name) data.name = name;
                await API_EDIT_FRONTPAGE(props.id, data);
                if (typeof window !== undefined) {
                  window.location.href = "/fEdit";
                }
              }}
            >
              Save texts
            </button>
          </div>
        </div>
        <div className="personOBJ">
          <input
            onChange={e => {
              setName2(e.target.value);
            }}
            placeholder={props.name2}
          />
          <div>
            {props.image2 ? (
              <>
                <img
                  alt={noImage}
                  src={process.env.REACT_APP_URL + "public/" + props.image2}
                />
                <button
                  onClick={async () => {
                    await API_EDIT_FRONTPAGE(props.id, {image2: ""});
                    API_SUB.remove("", "profilePic", "", props.image2);
                    if (typeof window !== undefined) {
                      window.location.href = "/fEdit";
                    }
                  }}
                >
                  Remove image
                </button>
              </>
            ) : (
              <>
                <label htmlFor={"file-upload2"} className="custom-file-upload">
                  {file2 ? "Set file name: " : "Upload Image"}
                </label>
                <input
                  className="hidden"
                  accept="image/png, image/jpeg"
                  id={"file-upload2"}
                  onChange={event => {
                    setFile2(event.target.files[0]);
                  }}
                  type="file"
                />
                {file2 ? (
                  <>
                    <input
                      placeholder="File name"
                      onChange={e => {
                        const reg = /[^A-Za-z0-9_-]/;

                        if (!e.target.value.match(reg)) {
                          setFileName2(e.target.value);
                        }
                      }}
                    />
                    {file2 && fileName2 ? (
                      <button
                        onClick={async () => {
                          const r = await API_SEND_FILE(file2, fileName2, true);
                          if (r.data === true) {
                            const fName =
                              fileName2 +
                              "." +
                              file2.name.substring(
                                file2.name.lastIndexOf(".") + 1,
                                file2.name.length
                              );
                            await API_EDIT_FRONTPAGE(props.id, {
                              image2: fName
                            });
                            if (typeof window !== undefined) {
                              window.location.href = "/fEdit";
                            }
                          }
                        }}
                      >
                        Uplaod
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            <Editor
              onChange={val => {
                setText2(val);
              }}
              value={props.nameText2}
            />
            <button
              onClick={async () => {
                const data = {};
                if (name2) data.name2 = name2;
                if (text2) data.nameText2 = text2;

                await API_EDIT_FRONTPAGE(props.id, data);
                if (typeof window !== undefined) {
                  window.location.href = "/fEdit";
                }
              }}
            >
              Save texts
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="aboutCreators">
      <h1>Möt skaparna bakom Målvaktsskolan</h1>
      <div className="personCont">
        <div className="personOBJ">
          {props.name ? <h1>{props.name}</h1> : <h1>No name</h1>}

          {props.image1 ? (
            <img
              alt="owner"
              src={process.env.REACT_APP_URL + "public/" + props.image1}
            />
          ) : (
            <img src={noImage} alt="noImage" />
          )}
          <p dangerouslySetInnerHTML={{__html: props.nameText}} />
        </div>
        <div className="personOBJ">
          {props.name2 ? <h1>{props.name2}</h1> : <h1>No name</h1>}
          {props.image2 ? (
            <img
              alt="owner"
              src={process.env.REACT_APP_URL + "public/" + props.image2}
            />
          ) : (
            <img src={noImage} alt="noImage" />
          )}
          <p
            className="person-text"
            dangerouslySetInnerHTML={{__html: props.nameText2}}
          />
        </div>
      </div>
    </div>
  );
}

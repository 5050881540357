import React, { createContext, useEffect, useReducer } from "react";

export const tpCTX = createContext({});

function reducer(state, action) {
  let tempArr;
  switch (action.type) {
    case "add":
      switch (action.objType) {
        case "tab":
          tempArr = state.tabs;
          if (tempArr === undefined) {
            tempArr = [];
          }
          action.payload.posts = [];
          tempArr.push(action.payload);
          return { ...state, tabs: tempArr };

        case "post":
          tempArr = state.tabs.map(tab => {
            if (tab._id === action.tabID) {
              tab.posts.push(action.payload);
            }
            return tab;
          });
          return { ...state, tabs: tempArr };

        case "imgLink":
          return { ...state, imgLink: action.payload };

        default:
          return { ...state };
      }

    case "edit":
      switch (action.objType) {
        case "activeTab":
          return { ...state, activeTab: action.payload };

        case "tab":
          tempArr = state.tabs.map(tab => {
            if (tab._id === action.id) {
              tab.title = action.payload;
            }
            return tab;
          });
          return { ...state, tabs: tempArr };

        case "post":
          //Requires: the post ID, tabID, field

          tempArr = state.tabs.map(tab => {
            if (tab._id === action.tabID) {
              tab.posts = tab.posts.map(post => {
                if (post._id === action.id) {
                  if (action.field === "title") post.title = action.payload;
                  if (action.field === "text") post.text = action.payload;
                  if (action.field === "imgLink")
                    post.fileName = action.payload.fileName;
                }
                return post;
              });
            }
            return tab;
          });

          return { ...state, tabs: tempArr };

        default:
          break;
      }
      break;

    case "remove":
      switch (action.objType) {
        case "tab":
          tempArr = state.tabs.filter(tab => {
            if (tab._id === action.payload) {
              return false;
            }
            return true;
          });
          return { ...state, tabs: tempArr };

        case "post":
          tempArr = state.tabs.map(tab => {
            if (tab._id === action.tabID) {
              tab.posts = tab.posts.filter(post => {
                if (post._id === action.payload) {
                  return false;
                }
                return true;
              });
            }
            return tab;
          });
          return { ...state, tabs: tempArr };

        case "imgLink":
          //Build up new tabs array
          tempArr = state.tabs.map(tab => {
            tab.posts.map(post => {
              if (post._id === action.payload.id) {
                post.fileName = action.payload.fileName;
              }
              return post;
            });
            return tab;
          });
          return { ...state, tabs: tempArr };

        default:
          break;
      }

      break;

    case "dataReady":
      return {
        tabs: action.tabs,
        posts: action.posts,
        activeTab: 0,
        objectID: action.objectID
      };

    default:
      return { ...state };
  }
}

const initialState = {
  tabs: [],
  posts: [],
  activeTab: 0,
  objectID: undefined
};

const Store = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: "dataReady",
      tabs: props.tabs,
      posts: [],
      objectID: props.objectID
    });
  }, [props.tabs, props.objectID, props.imgLink]);

  return (
    <tpCTX.Provider value={[state, dispatch]}>{props.children}</tpCTX.Provider>
  );
};

export default Store;

import React, {useState} from "react";
import "./styles/Footer.css";
import Editor from "../Subcategory/Objects/Editor";
import API_EDIT_FRONTPAGE from "./API_EDIT_FRONTPAGE";
export default function Footer(props) {
  const [text, setText] = useState(undefined);
  return props.edit ? (
    <div className="container">
      <h1>5</h1>
      <Editor
        onChange={val => {
          setText(val);
        }}
        value={props.text}
      />
      <button
        onClick={async () => {
          await API_EDIT_FRONTPAGE(props.id, {footer: text});
          if (window !== undefined) {
            window.location.href = "/fEdit";
          }
        }}
      >
        Send
      </button>
    </div>
  ) : (
    <div>
      <div className="footer">
        <p dangerouslySetInnerHTML={{__html: props.text}} />
      </div>
      <p className="credits">
        <a href="https://www.linkedin.com/in/erik-andersson-ba23ba188/">
          Erik Andersson
        </a>
        : Code |{" "}
        <a href="https://www.linkedin.com/in/dorian-sokolowski-633305190/">
          Dorian sokolowski
        </a>
        : Deployment to AWS |{" "}
        <a href="https://www.linkedin.com/in/veronica-arias-hansson-299456184/">
          Veronica Arias Hansson
        </a>{" "}
        &{" "}
        <a href="https://www.linkedin.com/in/anna-b%C3%A4ckdahl-8a32431a1/">
          Anna Bäckdahl
        </a>{" "}
        &{" "}
        <a href="https://www.linkedin.com/in/helena-l%C3%B6nn-far%C3%ADas-916b96191/">
          Helena Lönn Farías
        </a>
        : UX & Design
      </p>
    </div>
  );
}

import axios from "axios";

const url = process.env.REACT_APP_URL;

export default async function Register(email) {
  return await axios
    .post(url + "validEmail", {
      email: email
    })
    .then(res => {
      if (res.data !== false) {
        return res.data;
      } else {
        return undefined;
      }
    })
    .catch(err => {
      console.log("Error code: 7");
    });
}

import React from "react";

export default function SidemenuStep(props) {
  return (
    <div className="smStep">
      <p
        className="smCloseBtn"
        onClick={() => {
          props.setOpen(0);
        }}
      >
        Close
      </p>
      <p className={" color_module" + props.step}>Modul {props.step}</p>
      <hr className={"bar color_moduleb" + props.step}></hr>
      <div className="headers">
        {props.data.map(obj => {
          if (obj.type === 1) {
            if (props.id === obj._id) {
              return (
                <a
                  className="activeCat"
                  href={"/category/" + obj._id}
                  key={obj._id}
                >
                  {obj.title}
                </a>
              );
            } else {
              return (
                <a href={"/category/" + obj._id} key={obj._id}>
                  {obj.title}
                </a>
              );
            }
          }
          return "";
        })}
      </div>
      <div className={"row-2 color_module" + props.step}>
        <p>Kunskaper och Träning</p>
        <p>Intentioner och Coaching</p>
      </div>
      <div className="step-main">
        {props.data.map(obj => {
          if (obj.type === 2) {
            if (props.id === obj._id) {
              return (
                <div key={obj._id}>
                  <a href={"/category/" + obj._id} className="row-2">
                    <p className="activeCat">{obj.title}</p>
                    <p className="activeCat">{obj.title2}</p>
                  </a>
                </div>
              );
            } else {
              return (
                <div key={obj._id}>
                  <a href={"/category/" + obj._id} className="row-2">
                    <p>{obj.title}</p>
                    <p>{obj.title2}</p>
                  </a>
                </div>
              );
            }
          }
          return "";
        })}
        <p className={"color_module" + props.step}>Progression</p>
        <div>
          {props.data.map(obj => {
            if (obj.type === 3) {
              if (props.id === obj._id) {
                return (
                  <div key={obj._id}>
                    <a href={"/category/" + obj._id} className="row-2">
                      <p className="activeCat">{obj.title}</p>
                      <p className="activeCat">{obj.title2}</p>
                    </a>
                  </div>
                );
              } else {
                return (
                  <div key={obj._id}>
                    <a href={"/category/" + obj._id} className="row-2">
                      <p>{obj.title}</p>
                      <p>{obj.title2}</p>
                    </a>
                  </div>
                );
              }
            }
            return "";
          })}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import AuthContextProvider from "./context/authContext";
import Router from "./Components/Router";

require("dotenv").config();

function App() {
  return (
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  );
}

export default App;

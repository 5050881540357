import React, { useState, useContext } from "react";
import REMOVE_POST from "./REMOVE_POST";

import API_SUB from "../../../../../API/API_SUB";
import { tpCTX } from "../../../../../context/tpCTX";
import { AuthContext } from "../../../../../context/authContext";
import Editor from "../../Editor";

export default function Posts(props) {
  const [data, dispatch] = useContext(tpCTX);
  // eslint-disable-next-line no-unused-vars
  const [authData, authDispatc] = useContext(AuthContext);

  const [newPost, setnewPost] = useState({
    title: "",
    text: ""
  });

  return (
    <div className="posts">
      {data.tabs.map(tab => {
        if (data.tabs.indexOf(tab) === data.activeTab) {
          return tab.posts.map(post => {
            return (
              <div className="post" key={post._id}>
                {authData.editMode ? (
                  <>
                    <input
                      className="post-title-edit"
                      value={newPost.title}
                      placeholder={post.title}
                      onChange={e => {
                        setnewPost({ ...newPost, title: e.target.value });
                      }}
                      onKeyDown={async e => {
                        if (e.keyCode === 13) {
                          const tRes = await API_SUB.edit(
                            post._id,
                            { title: newPost.title },
                            "post"
                          );
                          if (tRes === true) {
                            dispatch({
                              type: "edit",
                              objType: "post",
                              tabID: post.tabID,
                              id: post._id,
                              field: "title",
                              payload: newPost.title
                            });
                            setnewPost({ ...newPost, title: "" });
                          }
                        }
                      }}
                    />
                    <Editor
                      editMode={authData.editMode}
                      value={post.text}
                      onChange={data => {
                        setnewPost({ text: data });
                      }}
                      onSubmit={async newTitle => {
                        const res = await API_SUB.edit(
                          post._id,
                          { text: newTitle },
                          "post"
                        );

                        if (res === true) {
                          dispatch({
                            type: "edit",
                            objType: "post",
                            tabID: post.tabID,
                            payload: newTitle,
                            id: post._id,
                            field: "text"
                          });
                        }
                      }}
                    />
                    <REMOVE_POST
                      id={post._id}
                      objectID={tab.objectID}
                      tabID={post.tabID}
                    />
                    {post.fileName ? (
                      <button
                        className="remove-button"
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this?"
                            )
                          ) {
                            const res = await API_SUB.remove(
                              post._id,
                              "imgLink",
                              ""
                            );

                            if (res === true) {
                              dispatch({
                                type: "remove",
                                objType: "imgLink",
                                payload: {
                                  id: post._id,
                                  fileName: ""
                                }
                              });
                            }
                          }
                        }}
                      >
                        Remove Image link
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <h1 className="post-title">{post.title}</h1>
                    <p
                      className="post-text"
                      dangerouslySetInnerHTML={{ __html: post.text }}
                    ></p>
                    {post.fileName ? (
                      <a
                        className="imgLink"
                        href={"/hidden/" + post._id + "/" + props.subcatID}
                      >
                        <img
                          src={
                            process.env.REACT_APP_URL + "file/" + post.fileName
                          }
                          alt="missing file"
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            );
          });
        }
        return "";
      })}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Slideshow from "./Slideshow";
import "./styles/frontpage.scss";
import logo from "../../images/logo.png";

import AboutSteps from "./AboutSteps";
import AboutCreators from "./AboutCreators";
import AboutSite from "./AboutSite";
import Footer from "./Footer";
import axios from "axios";
import ProductInfo from "./ProductInfo";
export default function Frontpage(props) {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    async function getData() {
      const url = process.env.REACT_APP_URL + "frontpage";
      await axios
        .post(url)
        .then((res) => {
          if (res.data) {
            setData(res.data[0]);
          }
        })
        .catch((err) => {
          throw new Error("Failed fetching data, unable to connect to server");
        });
    }
    getData();
  }, []);

  return data ? (
    <div>
      <button onClick={props.isLogin} className="loginBtn">
        Login
      </button>
      <div>
        <img className="topLogo" src={logo} alt="logo" />
      </div>
      <Slideshow />
      <AboutSite title={data.aboutTitle} text={data.aboutText} />
      <ProductInfo
        title={data.worksTitle}
        text={data.worksText}
        text2={data.worksText2}
        img={data.infoImage}
      />
      <AboutSteps
        module1={data.module1}
        module2={data.module2}
        module3={data.module3}
      />
      <AboutCreators
        name={data.name}
        name2={data.name2}
        nameText={data.nameText}
        nameText2={data.nameText2}
        image1={data.image1}
        image2={data.image2}
      />
      <Footer text={data.footer} />
    </div>
  ) : (
    ""
  );
}

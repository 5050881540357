import React, { useContext } from "react";

import Tabs from "./TAB/Tabs";
import Posts from "./POST/Posts";
import ADD_POST from "./POST/ADD_POST";
import ADD_IMAGE_LINK from "./POST/ADD_IMAGE_LINK";

import { AuthContext } from "../../../../context/authContext";
import { tpCTX } from "../../../../context/tpCTX";

export default function TabPost(props) {
  // eslint-disable-next-line no-unused-vars
  const [auth, change] = useContext(AuthContext);
  // eslint-disable-next-line no-unused-vars
  const [ctx, dispatch] = useContext(tpCTX);

  return (
    <>
      <Tabs />
      <Posts subcatID={props.subcatID} />

      {auth.editMode ? ctx.tabs.length > 0 ? <ADD_POST /> : "" : ""}
      {auth.editMode ? <ADD_IMAGE_LINK /> : ""}
    </>
  );
}

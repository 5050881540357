import React, {useContext, useState, useEffect} from "react";
import {AuthContext} from "../../context/authContext";
import "./Login.css";
import logo from "../../images/logo.png";
import API_VERIFYEMAIL from "../../API/API_VERIFY-EMAIL";
import axios from "axios";
import API_REGISTER from "../../API/API_REGISTER";

function Login(props) {
  // eslint-disable-next-line no-unused-vars
  const [auth, changeStatus] = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [errorText, setErrorText] = useState("");

  const [img, setImg] = useState(undefined);

  const [data, setData] = useState({
    email: "",
    password: "",
    registerPW: ""
  });

  useEffect(() => {
    async function fetchData() {
      const url = process.env.REACT_APP_URL + "frontpage";
      const r = await axios.post(url);
      setImg(r.data[0].loginImage);
    }
    fetchData();
  }, []);

  return (
    <div className="container-login">
      <img
        id="bg"
        alt="img"
        src={process.env.REACT_APP_URL + "public/" + img}
      ></img>
      <div onClick={props.isNotLogin} className="container-image">
        <img src={logo} alt="LOGO" />
      </div>

      <div className="container-inputs">
        <label className="error">{errorText}</label>
        <label> Email:</label>
        <input
          value={data.email}
          onChange={e => {
            setData({...data, email: e.target.value});
          }}
        />
        <label> Password:</label>
        <input
          value={data.password}
          type="password"
          onChange={e => {
            setData({...data, password: e.target.value});
          }}
        />

        {!isLogin ? <label>Re-type password</label> : ""}
        {!isLogin ? (
          <input
            value={data.registerPW}
            type="password"
            onChange={e => {
              setData({...data, registerPW: e.target.value});
            }}
          />
        ) : (
          ""
        )}
        <button
          onClick={async () => {
            if (isLogin) {
              changeStatus.login(data.email, data.password);
            } else {
              if (data.password === data.registerPW) {
                if (data.email === "") {
                  setErrorText("Missing Email");
                } else {
                  if (await API_VERIFYEMAIL(data.email)) {
                    if (await API_REGISTER(data.email, data.password)) {
                      alert("Registration successfull! Please log in.");
                      setData({
                        email: "",
                        password: "",
                        registerPW: ""
                      });
                      setIsLogin(true);
                    }
                  } else {
                    setErrorText("Email already registred");
                  }
                }
              } else {
                setErrorText("Passwords does not match!");
              }
            }
          }}
        >
          {isLogin ? "Login" : "Register"}
        </button>
        <label
          className="label-register"
          onClick={() => {
            setIsLogin(!isLogin);
          }}
        >
          {isLogin ? "  Not Registred?" : "Already have an account?"}
        </label>
      </div>
    </div>
  );
}

export default Login;

import React, {useState} from "react";
import Editor from "../Subcategory/Objects/Editor";
import "./styles/abouteSite.css";
import API_EDIT_FRONTPAGE from "./API_EDIT_FRONTPAGE";

export default function AboutSite(props) {
  const [title, setTitle] = useState(undefined);
  const [text, setText] = useState(undefined);

  return props.edit ? (
    <div className="aboutSiteEdit container">
      <h1>1</h1>

      <input
        onChange={e => {
          setTitle(e.target.value);
        }}
        placeholder={props.title}
      />
      <Editor
        onChange={val => {
          setText(val);
        }}
        value={props.text}
      />
      <button
        onClick={async () => {
          let data = {};
          if (title) data.aboutTitle = title;
          if (text) data.aboutText = text;

          await API_EDIT_FRONTPAGE(props.id, data);
          if (typeof window !== undefined) {
            window.location.href = "/fEdit";
          }
        }}
      >
        Send
      </button>
    </div>
  ) : (
    <div className="aboutSite">
      <h1>{props.title}</h1>
      <p dangerouslySetInnerHTML={{__html: props.text}} />
    </div>
  );
}

import React, {useContext} from "react";
import API_SUB from "../../../API/API_SUB";
import {subCTX} from "../../../context/subCTX";

function REMOVE_OBJ(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(subCTX);
  return (
    <button
      className="remove-obj"
      onClick={async () => {
        if (window.confirm("Are you sure you want to delete this object?")) {
          const res = await API_SUB.remove(props.id, "obj");

          if (res) {
            dispatch({
              type: "remove",
              objType: "obj",
              payload: props.id
            });
          }
        }
      }}
    >
      X
    </button>
  );
}

export default REMOVE_OBJ;

import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";
import API_SUB from "../API/API_SUB";
import jwt from "jsonwebtoken";

import IMG_ENG from "../images/eng.png";
import IMG_DE from "../images/ger.png";
import IMG_SWE from "../images/swe.png";

function Controls(props) {
  const [authState, changeStatus] = useContext(AuthContext);

  async function handleChangeLang(lang) {
    const decoded = await jwt.decode(authState.token);
    const res = await API_SUB.edit(
      "",
      { lang: lang, email: decoded.email },
      "lang"
    );
    if (res) {
      changeStatus.Lang(lang);
    }
  }

  return (
    <div className="controls">
      {props.frontpage ? (
        <div className="lang">
          <img
            onClick={() => {
              handleChangeLang("en");
            }}
            alt="eng"
            src={IMG_ENG}
          />
          <img
            src={IMG_DE}
            alt="de"
            onClick={() => {
              handleChangeLang("de");
            }}
          ></img>
          <img
            src={IMG_SWE}
            onClick={() => {
              handleChangeLang("swe");
            }}
            alt="swe"
          ></img>
        </div>
      ) : (
        ""
      )}

      <button
        className={
          props.frontpage ? "button-logout-frontpage" : "button-logout"
        }
        onClick={() => {
          changeStatus.logout();
        }}
      >
        Logga ut
      </button>

      {authState.type === "admin" ? (
        <>
          <button
            className="button-edit"
            onClick={() => {
              changeStatus.changeEdit();
            }}
          >
            Edit
          </button>
          <a href="/fEdit">
            <button>Frontpage Edit</button>
          </a>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Controls;

import React, {useState} from "react";
import Editor from "../Subcategory/Objects/Editor";
import API_SEND_FILE from "./API_SEND_FILE";
import API_EDIT_FRONTPAGE from "./API_EDIT_FRONTPAGE";
import API_SUB from "../../API/API_SUB";
import "./styles/productInfo.css";
import noImage from "../../images/unavailable.png";

export default function ProductInfo(props) {
  const [title, setTitle] = useState(undefined);
  const [text, setText] = useState(undefined);
  const [text2, setText2] = useState(undefined);
  const [file, setFile] = useState(undefined);

  async function send() {
    let data = {};
    if (title) data.worksTtitle = title;
    if (text) data.worksText = text;
    if (text2) data.worksText2 = text2;
    await API_EDIT_FRONTPAGE(props.id, data);
    if (window !== undefined) {
      window.location.href = "/fEdit";
    }
  }

  return props.edit ? (
    <div className="aboutSiteEdit container">
      <h1>2</h1>
      <input
        onChange={e => {
          setTitle(e.target.value);
        }}
        placeholder={props.title}
      />
      <Editor
        onChange={val => {
          setText(val);
        }}
        value={props.text}
      />
      <Editor
        onChange={val => {
          setText2(val);
        }}
        value={props.text2}
      />
      <button
        onClick={() => {
          send();
        }}
      >
        Send
      </button>
      {props.img ? (
        <div>
          <img
            alt="NO"
            src={process.env.REACT_APP_URL + "public/" + props.img}
          />
          <button
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to delete this image?")
              ) {
                API_SUB.remove("", "profilePic", "", props.img);
                API_EDIT_FRONTPAGE(props.id, {infoImage: ""});
                if (window !== undefined) {
                  window.location.href = "/fEdit";
                }
              }
            }}
          >
            Remove
          </button>
        </div>
      ) : (
        <div>
          <label htmlFor={"daofji9043d"} className="custom-file-upload">
            {file ? file.name : "Upload Image"}
          </label>
          <input
            className="hidden"
            accept="image/png, image/jpeg"
            id={"daofji9043d"}
            onChange={event => {
              setFile(event.target.files[0]);
            }}
            type="file"
          />
          {file ? (
            <>
              <button
                onClick={() => {
                  setFile(undefined);
                }}
              >
                Remove
              </button>
              <button
                onClick={async () => {
                  const r = await API_SEND_FILE(file, "_____infoImage", true);

                  const fName =
                    "_____infoImage" +
                    "." +
                    file.name.substring(
                      file.name.lastIndexOf(".") + 1,
                      file.name.length
                    );

                  if (r.data === true) {
                    await API_EDIT_FRONTPAGE(props.id, {
                      infoImage: fName
                    });
                    if (window !== undefined) {
                      window.location.href = "/fEdit";
                    }
                  }
                }}
              >
                Send
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="product-info">
      <div className="textImage">
        <h1 dangerouslySetInnerHTML={{__html: props.title}} />
        <p dangerouslySetInnerHTML={{__html: props.text}} />
        <div className="siteSampleContainer">
          {props.img ? (
            <img
              alt="siteSample"
              src={process.env.REACT_APP_URL + "public/" + props.img}
            />
          ) : (
            <img src={noImage} alt={noImage} />
          )}
        </div>
      </div>
      <div className="productText">
        <p dangerouslySetInnerHTML={{__html: props.text2}} />
      </div>
    </div>
  );
}

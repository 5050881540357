import React, {useEffect, useState} from "react";
import axios from "axios";
import "./styles/frontPageEdit.css";
import Cookies from "universal-cookie";
import API_SUB from "../../API/API_SUB";

import Alert from "../Alert";
import logo from "../../images/logo.png";

import AboutSite from "./AboutSite";
import ProductInfo from "./ProductInfo";
import AboutSteps from "./AboutSteps";
import AboutCreators from "./AboutCreators";
import Footer from "./Footer";
import LoginEdit from "./LoginEdit";

import imgGuide from "../../images/frontpage.png";

const cookie = new Cookies();

export default function FrontpageEdit(props) {
  const [slideShow, setSlideShow] = useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState(undefined);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    async function fetchData() {
      const res = await axios.post(process.env.REACT_APP_URL + "slideShow");
      setSlideShow(res.data);

      const url = process.env.REACT_APP_URL + "frontpage";
      const r = await axios.post(url);

      if (r.data) {
        setData(r.data[0]);
      }
    }
    fetchData();
  }, []);

  async function sendFile(isPublicFolder) {
    const fd = new FormData();

    const config = {
      headers: {
        authorization: cookie.get("token"),
        folder: isPublicFolder ? "public" : "",
        slideShow: true
      }
    };

    const fName =
      fileName +
      "." +
      file.name
        .substring(file.name.lastIndexOf(".") + 1, file.name.length)
        .toLowerCase();

    console.log(fName);

    fd.append("file", file, fName);

    await axios
      .post(process.env.REACT_APP_URL + "upload", fd, config)
      .then(res => {
        console.log(res.data);

        if (res.data === true) {
          setSlideShow([...slideShow, fName]);
        } else {
          setAlertMessage(
            "Ogiltligt filnamn. Namnet finns redan hos en annan fil på servern"
          );
          setAlertOpen(true);
        }
      })
      .catch(err => {
        throw err;
      });
  }

  return data ? (
    <div>
      <a className="homeButton" href="/">
        <img src={logo} alt="logo" />
      </a>
      {alertOpen ? (
        <Alert
          close={() => {
            setAlertOpen(false);
          }}
          message={alertMessage}
        />
      ) : (
        ""
      )}
      <div className="container slideshowEdit">
        <h1>Bildspel</h1>

        <div className="uploader">
          <label htmlFor="slideshow-uploader" className="custom-file-upload">
            {file ? " Välj namn" : "Ladda upp"}
          </label>
          {file ? (
            <input
              placeholder="Namn: a-z | 0-9 | _ | -"
              type="text"
              value={fileName}
              onChange={e => {
                const reg = /[^A-Za-z0-9_-]/;

                if (!e.target.value.match(reg)) {
                  setFileName(e.target.value);
                }
              }}
            />
          ) : (
            ""
          )}
          <input
            accept="image/*"
            onChange={event => {
              setFile(event.target.files[0]);
            }}
            id="slideshow-uploader"
            type="file"
          />
          {file ? (
            <button
              onClick={() => {
                sendFile(true);
              }}
            >
              Upload
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="images">
          {slideShow
            ? slideShow.map(img => {
                let url = process.env.REACT_APP_URL + "public/slideShow/" + img;
                return (
                  <div key={img} className="slideshowEditImg">
                    <img
                      alt="img"
                      src={url}
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this image?"
                          )
                        ) {
                          const temp = slideShow.filter(i => {
                            if (i === img) {
                              return false;
                            }
                            return true;
                          });
                          setSlideShow(temp);
                          API_SUB.remove("", "publicVid", "", img);
                        }
                      }}
                    />
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <AboutSite
        edit
        title={data.aboutTitle}
        text={data.aboutText}
        id={data._id}
      />
      <ProductInfo
        edit
        title={data.worksTitle}
        text={data.worksText}
        text2={data.worksText2}
        id={data._id}
        img={data.infoImage}
      />
      <AboutSteps
        edit
        module1={data.module1}
        module2={data.module2}
        module3={data.module3}
        id={data._id}
      />
      <AboutCreators
        edit
        name={data.name}
        name2={data.name2}
        nameText={data.nameText}
        nameText2={data.nameText2}
        image1={data.image1}
        image2={data.image2}
        id={data._id}
      />
      <Footer id={data._id} edit text={data.footer} />
      <LoginEdit id={data._id} img={data.loginImage} />

      <img className="imgGuide" src={imgGuide} alt="guide" />
    </div>
  ) : (
    "Loading.."
  );
}

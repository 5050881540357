import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const url = process.env.REACT_APP_URL;

export default {
  get: async id => {
    return await axios
      .post(
        url + "query",
        {
          type: "subcategory",
          lang: cookies.get("lang"),
          id: id
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code: 2");
      });
  },
  getHidden: async id => {
    return await axios
      .post(
        url + "query",
        {
          type: "hidden",
          lang: cookies.get("lang"),
          id: id
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code:  2");
      });
  },
  add: async (obj, type) => {
    return await axios
      .post(
        url + "add",
        {
          type: type,
          lang: cookies.get("lang"),
          data: obj
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code: 3");
      });
  },
  remove: async (id, type, field, name) => {
    return await axios
      .post(
        url + "remove",
        {
          type: type,
          lang: cookies.get("lang"),
          id: id,
          field: field,
          name: name
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log("Error code: 4");
      });
  },
  edit: async (id, params, type) => {
    return await axios
      .post(
        url + "edit",
        {
          type: type,
          lang: cookies.get("lang"),
          id: id,
          payload: params
        },
        {
          headers: {
            authorization: cookies.get("token")
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log(err);

        console.log("Error code:  5");
      });
  }
};

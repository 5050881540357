import React, {useState} from "react";
import API_SEND_FILE from "./API_SEND_FILE";
import API_EDIT_FRONTPAGE from "./API_EDIT_FRONTPAGE";
import API_SUB from "../../API/API_SUB";

export default function LoginEdit(props) {
  const [file, setFile] = useState(undefined);

  return (
    <div className="loginEdit container">
      <h1>Login page background</h1>

      {props.img ? (
        <div>
          <img
            alt="NO"
            src={process.env.REACT_APP_URL + "public/" + props.img}
          />
          <button
            onClick={async () => {
              if (
                window.confirm("Are you sure you want to delete this image?")
              ) {
                API_SUB.remove("", "profilePic", "", props.img);
                API_EDIT_FRONTPAGE(props.id, {loginImage: ""});
                if (window !== undefined) {
                  window.location.href = "/fEdit";
                }
              }
            }}
          >
            Remove
          </button>
        </div>
      ) : (
        <div>
          <label htmlFor={"daofji9043"} className="custom-file-upload">
            {file ? file.name : "Upload Image"}
          </label>
          <input
            className="hidden"
            accept="image/png, image/jpeg"
            id={"daofji9043"}
            onChange={event => {
              setFile(event.target.files[0]);
            }}
            type="file"
          />
          {file ? (
            <>
              <button
                onClick={() => {
                  setFile(undefined);
                }}
              >
                Remove
              </button>
              <button
                onClick={async () => {
                  const r = await API_SEND_FILE(file, "_____loginImage", true);

                  const fName =
                    "_____loginImage" +
                    "." +
                    file.name.substring(
                      file.name.lastIndexOf(".") + 1,
                      file.name.length
                    );

                  if (r.data === true) {
                    await API_EDIT_FRONTPAGE(props.id, {
                      loginImage: fName
                    });
                    if (window !== undefined) {
                      window.location.href = "/fEdit";
                    }
                  }
                }}
              >
                Send
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/Orange.PNG");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../images/Pink.PNG");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../images/Blue.PNG");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".aboutSteps {\r\n  display: grid;\r\n\r\n  grid-template-columns: 1fr 1fr 1fr;\r\n}\r\n\r\n.aboutSteps .title {\r\n  text-align: center;\r\n  font-size: 30px;\r\n  margin-bottom: 5vh;\r\n}\r\n\r\n.aboutSteps > div {\r\n  padding: 5vh 5vw;\r\n  min-height: 30vh;\r\n}\r\n.orangeBG {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n}\r\n\r\n.pinkBG {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n}\r\n\r\n.blueBG {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n}\r\n\r\n@media only screen and (max-width: 1400px) {\r\n  .aboutSteps {\r\n    display: grid;\r\n    grid-template-columns: 1fr;\r\n  }\r\n}\r\n", ""]);
// Exports
module.exports = exports;

import React from "react";
import API_SUB from "../../../../../API/API_SUB";
import {tpCTX} from "../../../../../context/tpCTX";

export default class ADD_TAB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {
    return (
      <tpCTX.Consumer>
        {ctx => {
          return (
            <input
              placeholder="New Tab"
              className="add-tab"
              value={this.state.title}
              onChange={e => {
                this.setState({title: e.target.value});
              }}
              onKeyDown={async e => {
                if (e.keyCode === 13) {
                  if (this.state.title !== "") {
                    const newTab = await API_SUB.add(
                      {title: this.state.title, objectID: ctx[0].objectID},
                      "tab"
                    );
                    if (newTab) {
                      ctx[1]({
                        type: "add",
                        objType: "tab",
                        payload: newTab
                      });
                      this.setState({title: ""});
                    }
                  } else {
                    alert("Missing tab title");
                  }
                }
              }}
            />
          );
        }}
      </tpCTX.Consumer>
    );
  }
}

import React, { useState, useContext } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

import logo from "../../../../../images/logo.png";

import API_SUB from "../../../../../API/API_SUB";
import { tpCTX } from "../../../../../context/tpCTX";

const cookie = new Cookies();

const url = process.env.REACT_APP_URL;

function FileUploader() {
  const [file, setFile] = useState();
  const [ctx, disptach] = useContext(tpCTX);
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState("");
  const [selectedPost, setSelectedPost] = useState(undefined);

  async function sendFile() {
    const fd = new FormData();

    const config = {
      headers: {
        authorization: cookie.get("token"),
      },
    };

    const fName =
      fileName +
      "." +
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

    fd.append("file", file, fName);
    try {
      const res = await axios.post(url + "upload", fd, config);

      if (res.data === true) {
        const res = await API_SUB.edit(
          selectedPost,
          { fileName: fName },
          "post"
        );
        if (res) {
          disptach({
            type: "remove",
            objType: "imgLink",
            field: "imgLink",

            payload: {
              id: selectedPost,
              fileName: fName,
            },
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(
        "Server error. File alreadt exists, try using another name for the file."
      );
    }
  }

  const num = Math.random(0, 1000000);

  return (
    <div className="file-uploader">
      <p>Add Image link</p>

      <label htmlFor={"file-upload" + num} className="custom-file-upload">
        {file ? (fileName ? "Select Post" : "Set file name: ") : "ATTATCH"}
      </label>
      <input
        id={"file-upload" + num}
        onChange={(event) => {
          setFile(event.target.files[0]);
        }}
        type="file"
        accept="image/png, image/jpeg"
      />

      {file ? (
        <>
          <input
            type="text"
            value={fileName}
            onChange={(e) => {
              const reg = /[^A-Za-z0-9_-]/;

              if (!e.target.value.match(reg)) {
                setFileName(e.target.value);
              }
            }}
          />
          {fileName ? (
            <>
              <select
                onChange={(e) => {
                  setSelectedPost(e.target.value);
                }}
              >
                <option>Select Post</option>
                {ctx.tabs.map((tab) => {
                  return tab.posts.map((post) => {
                    if (post.fileName) {
                      return false;
                    } else {
                      return (
                        <option value={post._id} key={post._id}>
                          {post.title}
                        </option>
                      );
                    }
                  });
                })}
              </select>
              {selectedPost ? (
                <button
                  disabled={loading}
                  className="add-object"
                  onClick={() => {
                    if (selectedPost !== undefined && fileName !== "") {
                      sendFile();
                      setLoading(true);
                    } else {
                      alert("You must select a post for the image");
                    }
                  }}
                >
                  {loading ? (
                    <img alt="Loading" src={logo} className="uploadingIcon" />
                  ) : (
                    "Upload"
                  )}
                </button>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default FileUploader;

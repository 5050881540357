import React, { useContext, useState } from "react";
import API_CATEGORIES from "../../API/API_CATEGORIES";
import { CategoryContext } from "../../context/catCTX";

function ADD_CATEGORY() {
  // eslint-disable-next-line no-unused-vars
  const [cats, dispatch] = useContext(CategoryContext);

  const [data, setData] = useState({
    title: "",
    title2: "",
    step: undefined,
    type: -1
  });

  async function handleSubmit() {
    if (data.step === undefined && data.type !== 0)
      alert("You must select a step");

    const res = await API_CATEGORIES.add(data);
    if (res) {
      dispatch({ type: "add", payload: res });
    }
  }

  return (
    <div className="ADD-CATEGORY">
      <select
        onChange={e => {
          setData({});
          setData({ ...data, type: parseInt(e.target.value) });
        }}
      >
        <option value={-1}>Select Type</option>
        <option value={0}>Sidemenu</option>
        <option value={1}>Header</option>
        <option value={2}>Main</option>
        <option value={3}>Post Progression</option>
      </select>
      {data.type !== -1 ? (
        <>
          <input
            placeholder="First Title.."
            onChange={e => {
              setData({ ...data, title: e.target.value });
            }}
          />
          {data.type !== 0 ? (
            <>
              {data.type !== 1 ? (
                <input
                  placeholder="Second Title.."
                  onChange={e => {
                    setData({ ...data, title2: e.target.value });
                  }}
                />
              ) : (
                ""
              )}
              <select
                onChange={e => {
                  setData({ ...data, step: parseInt(e.target.value) });
                }}
              >
                <option>Select Module</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </select>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {data.type !== -1 ? (
        <button
          onClick={async () => {
            //Add loading icon to button
            await handleSubmit();
          }}
        >
          Send
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default ADD_CATEGORY;

import React, {createContext, useEffect, useReducer} from "react";

export const videoCTX = createContext({});

function reducer(state, action) {
  let tempArr;
  switch (action.type) {
    case "add":
      switch (action.objType) {
        case "video":
          return {...state, videos: [...state.videos, action.payload]};

        default:
          return {...state};
      }

    case "edit":
      switch (action.objType) {
        case "title":
          tempArr = state.videos;
          tempArr = tempArr.map(vid => {
            if (vid._id === action.id) {
              vid.title = action.payload;
            }
            return vid;
          });
          return {...state, videos: tempArr};

        case "text":
          tempArr = state.videos;
          tempArr = tempArr.map(vid => {
            if (vid._id === action.id) {
              vid.text = action.payload;
            }
            return vid;
          });
          return {...state, videos: tempArr};

        case "video":
          tempArr = state.videos;
          tempArr = tempArr.map(vid => {
            if (vid._id === action.id) {
              vid.video = action.payload;
            }
            return vid;
          });
          return {...state, videos: tempArr};

        case "activeVid":
          const exists = state.videos[state.activeVideo + action.payload];

          if (exists) {
            return {...state, activeVideo: state.activeVideo + action.payload};
          } else {
            return state;
          }

        default:
          return {...state};
      }

    case "remove":
      switch (action.objType) {
        case "video":
          tempArr = state.videos.filter(obj => {
            if (obj._id === action.id) {
              return false;
            } else {
              return true;
            }
          });
          return {...state, videos: tempArr};

        default:
          return {...state};
      }

    case "dataReady":
      return {
        videos: action.videos,
        activeVideo: 0,
        objectID: action.objectID,
        subcatID: action.subcatID
      };

    default:
      return {...state};
  }
}

const initialState = {
  videos: [],
  activeVideo: 0,
  objectID: undefined,
  subcatID: undefined
};

const VideoStore = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: "dataReady",
      videos: props.videos,
      objectID: props.objectID,
      subcatID: props.subcatID
    });
  }, [props.videos, props.objectID, props.subcatID]);

  return (
    <videoCTX.Provider value={[state, dispatch]}>
      {props.children}
    </videoCTX.Provider>
  );
};

export default VideoStore;

import React, {useContext} from "react";
import {videoCTX} from "../../../../context/videoCTX";
import API_SUB from "../../../../API/API_SUB";

export default function ADD_VIDEO(props) {
  const [data, dispatch] = useContext(videoCTX);
  async function handleSubmuit() {
    const res = await API_SUB.add({objectID: data.objectID}, "video");
    if (res) {
      dispatch({
        type: "add",
        objType: "video",
        payload: res
      });
    }
  }

  return (
    <button
      className="add-object"
      onClick={async () => {
        await handleSubmuit();
      }}
    >
      Add video
    </button>
  );
}

import axios from "axios";

const url = process.env.REACT_APP_URL;

export default async function Login(email, password) {
  return await axios
    .post(url + "login", {
      email: email,
      password: password
    })
    .then(res => {
      if (res.data !== false) {
        return res.data;
      } else {
        return false;
      }
    })
    .catch(err => {
      console.log("Error code: 6");
    });
}

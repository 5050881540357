import React, {useContext, useState} from "react";
import API_SUB from "../../../../API/API_SUB";
import {videoCTX} from "../../../../context/videoCTX";

import Editor from "../Editor";

export default function EDIT_VIDEO(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(videoCTX);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  return (
    <div className="edit-video">
      <input
        value={title}
        onChange={e => {
          setTitle(e.target.value);
        }}
        onKeyDown={async e => {
          if (e.keyCode === 13) {
            if (title !== "") {
              const res = await API_SUB.edit(
                props.video._id,
                {title: title},
                "video"
              );

              if (res) {
                dispatch({
                  type: "edit",
                  objType: "title",
                  id: props.video._id,
                  payload: title
                });
                setTitle("");
              }
            }
          }
        }}
        placeholder={props.video.title}
      />

      <Editor
        value={props.video.text}
        onChange={val => {
          setText(val);
        }}
      />
      <button
        className="add-object"
        onClick={async () => {
          const res = await API_SUB.edit(
            props.video._id,
            {text: text},
            "video"
          );
          if (res) {
            dispatch({
              type: "edit",
              objType: "text",
              id: props.video._id,
              payload: text
            });
          }
        }}
      >
        Save text
      </button>
    </div>
  );
}

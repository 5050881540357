import React, {createContext, useState} from "react";
import Cookies from "universal-cookie";
import API_LOGIN from "../API/API_LOGIN";
import jwt from "jsonwebtoken";

const cookies = new Cookies();

export const AuthContext = createContext([{}, () => {}]);

const AuthContextProvider = ({children}) => {
  const [state, setState] = useState({
    token: cookies.get("token"),
    lang: cookies.get("lang"),
    type: cookies.get("type"),
    editMode: false
  });

  async function Login(username, passowrd) {
    const token = await API_LOGIN(username, passowrd);
    const tokenData = jwt.decode(token);

    if (token !== undefined) {
      cookies.set("token", token, {path: "/"});
      cookies.set("lang", tokenData.lang, {path: "/"});
      if (tokenData.type === undefined) {
        cookies.set("type", "swe", {path: "/"});
      } else {
        cookies.set("type", tokenData.type, {path: "/"});
      }

      setState({token: token, lang: tokenData.lang, type: tokenData.type});
    } else {
      alert("Incorrect email or password.");
    }
  }

  function Logout() {
    cookies.remove("token", {path: "/"});
    cookies.remove("lang", {path: "/"});
    cookies.remove("type", {path: "/"});
    setState({token: undefined});
  }

  function changeEdit() {
    setState({
      ...state,
      editMode: !state.editMode
    });
  }

  function changeLang(lang) {
    cookies.set("lang", lang, {path: "/"});
    setState({
      ...state,
      lang: lang
    });
  }

  return (
    <AuthContext.Provider
      value={[
        state,
        {login: Login, logout: Logout, changeEdit: changeEdit, Lang: changeLang}
      ]}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import React, {useContext} from "react";
import API_SUB from "../../../API/API_SUB";
import {subCTX} from "../../../context/subCTX";
import tabPost_IMG from "../../../images/tabPost.png";
import videos_IMG from "../../../images/videos.png";

export default function TypeSelector(props) {
  // eslint-disable-next-line no-unused-vars
  const [ctx, dispatch] = useContext(subCTX);

  return (
    <div className="type-selector">
      <button
        className="typeSelect-button"
        onClick={async () => {
          const res = await API_SUB.edit(props.data._id, {type: 1}, "obj");

          if (res === true) {
            dispatch({
              type: "edit",
              payload: {
                _id: props.data._id,
                type: 1,
                tabs: [],
                subcatID: props.data._id
              }
            });
          }
        }}
      >
        <img src={tabPost_IMG} alt={"TabPost"} />
      </button>
      <button className="typeSelect-button">
        <img
          src={videos_IMG}
          alt={"Videos"}
          onClick={async () => {
            const res = await API_SUB.edit(props.data._id, {type: 2}, "obj");
            if (res === true) {
              dispatch({
                type: "edit",
                payload: {
                  _id: props.data._id,
                  type: 2,
                  videos: [],
                  subcatID: props.data._id
                }
              });
            }
          }}
        />
      </button>
    </div>
  );
}

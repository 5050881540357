import React, {createContext, useEffect, useReducer} from "react";
import API_SUB from "../API/API_SUB";

export const hiddenCTX = createContext({});

function reducer(state, action) {
  switch (action.type) {
    case "add":
      break;

    case "edit":
      switch (action.objType) {
        case "text":
          return {...state, videoText: action.payload};

        case "video":
          return {...state, video: action.payload};

        default:
          break;
      }
      break;

    case "remove":
      break;

    case "dataReady":
      return {
        video: action.payload.video,
        videoText: action.payload.videoText,
        id: action.payload.id
      };

    default:
      return {...state};
  }
}

const initialState = {
  video: undefined,
  videoText: undefined
};

const Store = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function getData() {
      const data = await API_SUB.getHidden(props.id);

      dispatch({
        type: "dataReady",
        payload: {
          video: data.video,
          videoText: data.videoText,
          id: props.id
        }
      });
    }

    getData();
  }, [props.id]);

  return (
    <hiddenCTX.Provider value={[state, dispatch]}>
      {props.children}
    </hiddenCTX.Provider>
  );
};

export default Store;

import React from "react";
import {AuthContext} from "../../../../../context/authContext";
import {tpCTX} from "../../../../../context/tpCTX";

import REMOVE_TAB from "./REMOVE_TAB";

import ADD_TAB from "./ADD_TAB";
import EDIT_TAB from "./EDIT_TAB";

export default class Tabs extends React.Component {
  render() {
    return (
      <tpCTX.Consumer>
        {tpCTX => {
          return (
            <AuthContext.Consumer>
              {actx => {
                return (
                  <div className="tabs">
                    {tpCTX[0].tabs
                      ? tpCTX[0].tabs.map(tab => {
                          let cname = "tab";
                          if (
                            tpCTX[0].tabs.indexOf(tab) === tpCTX[0].activeTab
                          ) {
                            cname = "tab active";
                          }

                          return (
                            <div key={tab._id}>
                              <div
                                className={cname}
                                onClick={() => {
                                  let index = tpCTX[0].tabs.indexOf(tab);
                                  tpCTX[1]({
                                    type: "edit",
                                    objType: "activeTab",
                                    payload: index
                                  });
                                }}
                              >
                                {actx[0].editMode ? (
                                  <EDIT_TAB
                                    id={tab._id}
                                    title={tab.title}
                                    className={cname}
                                    onClick={() => {
                                      let index = tpCTX[0].tabs.indexOf(tab);
                                      tpCTX[1]({
                                        type: "edit",
                                        objType: "activeTab",
                                        payload: index
                                      });
                                    }}
                                  />
                                ) : (
                                  tab.title
                                )}
                              </div>
                              {actx[0].editMode ? (
                                <REMOVE_TAB id={tab._id} />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })
                      : ""}
                    {actx[0].editMode ? (
                      <ADD_TAB objID={this.props.objectID} />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
            </AuthContext.Consumer>
          );
        }}
      </tpCTX.Consumer>
    );
  }
}

// {axtx => {
//     return ( <>
//       <div className="tabs">
//       {this.props.data.tabs.map(tab => {
//         const cName =
//           this.state.activeTab === tab._id ? "tab active" : "tab";
//         return (
//           <div
//             className={cName}
//             key={tab._id}
//             onClick={() => {
//               this.setState({activeTab: tab._id});
//             }}
//           >
//             {tab.title}

//              { actx[0].editMode ? (
//                   <REMOVE_TAB id={tab._id} objectID={tab.objectID} />
//                 ) : (
//                   ""
//                 )}

//           </div>
//         );
//       })}
//    {    actx[0].editMode ? <ADD_TAB objID={this.props.data._id} /> : ""}
//     </div>

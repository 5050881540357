// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/room.bmp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".product-info {\r\n  display: flex;\r\n  justify-items: space-between;\r\n\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  color: white;\r\n  padding: 5vh 10vw;\r\n}\r\n\r\n.textImage {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 50vw;\r\n}\r\n\r\n.textImage > h1 {\r\n  margin-bottom: 5vh;\r\n  font-weight: 400;\r\n}\r\n\r\n.siteSampleContainer {\r\n  padding-top: 5vh;\r\n  width: 30vw;\r\n}\r\n\r\n.siteSampleContainer > img {\r\n  width: 100%;\r\n  object-fit: cover;\r\n}\r\n\r\n.productText {\r\n  padding-left: 5vw;\r\n  width: 50vw;\r\n}\r\n\r\n@media only screen and (max-width: 1500px) {\r\n  .product-info {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .textImage {\r\n    width: 100%;\r\n  }\r\n\r\n  .siteSampleContainer {\r\n    padding-top: 5vh;\r\n    width: 100%;\r\n  }\r\n\r\n  .productText {\r\n    padding: 2em 0;\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n@media only screen and (max-width: 1000px) {\r\n  .product-info {\r\n    padding: 3vh 5vw;\r\n  }\r\n\r\n  .siteSampleContainer {\r\n    padding-top: 2vh;\r\n  }\r\n}\r\n", ""]);
// Exports
module.exports = exports;

import React, {useContext} from "react";

import {AuthContext} from "../../../../context/authContext";
import {videoCTX} from "../../../../context/videoCTX";
import UPLOAD_VIDEO from "./UPLOAD_VIDEO";
import "./video.css";

import ADD_VIDEO from "./ADD_VIDEO";
import EDIT_VIDEO from "./EDIT_VIDEO";
import MutliVideo from "./MultiVideo";
import API_SUB from "../../../../API/API_SUB";

export default function Videos(props) {
  // eslint-disable-next-line no-unused-vars
  const [auth, change] = useContext(AuthContext);
  const [data, dispatch] = useContext(videoCTX);

  return (
    <div className="videos">
      {auth.editMode ? (
        data.videos.map(vid => {
          return (
            <div
              className={
                auth.editMode ? "editMode video-container" : "video-container"
              }
              key={vid._id}
            >
              <EDIT_VIDEO video={vid} />
              {!vid.video ? (
                <UPLOAD_VIDEO id={vid._id} />
              ) : (
                <button
                  className="remove-button"
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this video?"
                      )
                    ) {
                      const r = await API_SUB.edit(vid._id, "", "unsetVideo");
                      if (r) {
                        dispatch({
                          type: "edit",
                          objType: "video",
                          payload: undefined,
                          id: vid._id
                        });
                      }
                    }
                  }}
                >
                  Delete Vid
                </button>
              )}
              <button
                className="remove-obj"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this specific video object?"
                    )
                  ) {
                    const r = await API_SUB.remove(vid._id, "videoObjvid");
                    if (r === true) {
                    }
                    dispatch({
                      type: "remove",
                      objType: "video",
                      id: vid._id
                    });
                  }
                }}
              >
                X
              </button>
            </div>
          );
        })
      ) : (
        <MutliVideo />
      )}

      {auth.editMode ? <ADD_VIDEO /> : ""}
    </div>
  );
}

import React, {useContext, useState} from "react";
import {CategoryContext} from "../../context/catCTX";
import API_CATEGORIES from "../../API/API_CATEGORIES";
function EDIT_CATEGORY(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(CategoryContext);
  const [newTitle, setNewTitle] = useState("");
  return (
    <input
      placeholder={props.title}
      value={newTitle}
      onChange={e => {
        setNewTitle(e.target.value);
      }}
      onKeyDown={async e => {
        if (e.keyCode === 13) {
          if (newTitle !== "") {
            const req =
              props.field === "title"
                ? await API_CATEGORIES.edit(props.id, {title: newTitle})
                : await API_CATEGORIES.edit(props.id, {title2: newTitle});
            if (await req) {
              dispatch({
                type: "edit",
                payload: {
                  value: newTitle,
                  _id: props.id,
                  field: props.field
                }
              });
              setNewTitle("");
            }
          } else {
            alert("Missing title");
          }
        }
      }}
    />
  );
}

export default EDIT_CATEGORY;

import React, {createContext, useEffect, useReducer, useContext} from "react";
import API_CATEGORIES from "../API/API_CATEGORIES";
import {AuthContext} from "../context/authContext";
export const CategoryContext = createContext({});

function reducer(state, action) {
  switch (action.type) {
    case "add":
      return {cats: [...state.cats, action.payload]};

    case "remove":
      const newCats = state.cats.filter(obj => {
        if (obj._id === action.payload) {
          return false;
        }
        return true;
      });
      return {cats: newCats};

    case "edit":
      const c = state.cats.filter(obj => {
        if (obj._id === action.payload._id) {
          obj[action.payload.field] = action.payload.value;
          return obj;
        }
        return true;
      });
      return {cats: c};

    case "dataReady":
      return {...state, cats: action.payload};

    case "activeStep":
      return {...state, activeStep: action.payload};

    default:
      return {...state};
  }
}

const initialState = {cats: [], activeStep: 1};

const Store = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [auth, changeAuth] = useContext(AuthContext);
  useEffect(() => {
    async function getCats() {
      const cats = await API_CATEGORIES.get();
      if (cats === false) {
        changeAuth.logout();
      } else {
        dispatch({
          type: "dataReady",
          payload: cats
        });
      }
    }
    getCats();
  }, [auth.lang, changeAuth]);

  return (
    <CategoryContext.Provider value={[state, dispatch]}>
      {props.children}
    </CategoryContext.Provider>
  );
};

export default Store;

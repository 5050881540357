import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();

const config = {
  headers: {
    authorization: cookie.get("token")
  }
};

const url = process.env.REACT_APP_URL + "edit";

export default async function API_EDIT_FRONTPAGE(id, data) {
  return await axios.post(
    url,
    {
      type: "frontpage",
      payload: data,
      id: id
    },
    config
  );
}

import React, {useState, useEffect} from "react";
import axios from "axios";
import "./styles/slideshow.scss";
import noImage from "../../images/unavailable.png";

let url = process.env.REACT_APP_URL;
export default function Slideshow(props) {
  const [active, setActive] = useState(0);
  const [images, setImages] = useState(undefined);

  useEffect(() => {
    async function fetchImages() {
      const req = await axios.post(url + "slideShow");
      if (req.data.length > 0) {
        setImages(req.data);
      }
    }

    fetchImages();
  }, []);

  return (
    <div className="slideShow">
      {images ? (
        <img src={url + "public/slideShow/" + images[active]} alt="img" />
      ) : (
        <img src={noImage} alt="noImage" />
      )}

      <div className="slideShowNav">
        {images ? (
          images.map(img => {
            let cname = "";

            if (images.indexOf(img) !== active) {
              cname = "grayFilter";
            }
            return (
              <img
                key={img}
                onClick={() => {
                  setActive(images.indexOf(img));
                }}
                src={url + "public/slideShow/" + img}
                alt="img"
                className={cname}
              />
            );
          })
        ) : (
          <img src={noImage} alt="noImage" />
        )}
      </div>
    </div>
  );
}

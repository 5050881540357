import React, {useContext} from "react";
import {tpCTX} from "../../../../../context/tpCTX";
import API_SUB from "../../../../../API/API_SUB";
function REMOVE_POST(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(tpCTX);
  return (
    <button
      className="remove-post"
      onClick={async () => {
        //Add custom alert (own comp)
        if (window.confirm("Are you sure you want to delete this?")) {
          const res = await API_SUB.remove(props.id, "post");

          if (res) {
            dispatch({
              type: "remove",
              objType: "post",

              tabID: props.tabID,
              payload: props.id
            });
          } else {
            alert("Deletion failed");
          }
        }
      }}
    >
      X
    </button>
  );
}

export default REMOVE_POST;

import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import DELETE_CATEGORY from "./DELETE_CATEGORY";
import EDIT_CATEGORY from "./EDIT_CATEGORY";
import logo from "../../images/logo.png";

export default function Step(props) {
  const [auth] = useContext(AuthContext);

  return (
    <div
      className={
        props.active === props.step
          ? "step "
          : props.active === -1
          ? "step"
          : "step hiddenStep " + props.active
      }
    >
      <p className={"bigger color_module" + props.step}>Modul {props.step}</p>
      <hr className={"bar color_moduleb" + props.step}></hr>
      <div className="headers">
        {props.data.map(obj => {
          if (obj.type === 1) {
            return auth.editMode ? (
              <div key={obj._id} className="row-3">
                <EDIT_CATEGORY id={obj._id} field="title" title={obj.title} />
                <p></p>
                <DELETE_CATEGORY id={obj._id} />
              </div>
            ) : (
              <a href={"/category/" + obj._id} key={obj._id}>
                {obj.title}
              </a>
            );
          }
          return "";
        })}
      </div>
      <div className={"bigger-medium row-3 color_module" + props.step}>
        <p>Kunskaper och Träning</p>
        <p>Intentioner och Coaching</p>
        <p className="align-center">Övningar</p>
      </div>
      <div className="step-main">
        {props.data.map(obj => {
          if (obj.type === 2) {
            return (
              <div className="row-10-2" key={obj._id}>
                {auth.editMode ? (
                  <div className="row-2">
                    <EDIT_CATEGORY
                      id={obj._id}
                      field="title"
                      title={obj.title}
                    />
                    <EDIT_CATEGORY
                      id={obj._id}
                      field="title2"
                      title={obj.title2}
                    />
                  </div>
                ) : (
                  <a href={"/category/" + obj._id} className="row-2">
                    <p>{obj.title}</p>
                    <p>{obj.title2}</p>
                  </a>
                )}

                {auth.editMode ? (
                  <DELETE_CATEGORY id={obj._id} />
                ) : (
                  <a href={"/category/exe/" + obj._id} className="exeLogo">
                    <img src={logo} alt={"logo"} />
                  </a>
                )}
              </div>
            );
          }
          return "";
        })}
      </div>
      <p className={"color_module" + props.step}>Progression</p>
      <div>
        {props.data.map(obj => {
          if (obj.type === 3) {
            return (
              <div className="row-10-2" key={obj._id}>
                {auth.editMode ? (
                  <div className="row-2">
                    <EDIT_CATEGORY
                      id={obj._id}
                      field="title"
                      title={obj.title}
                    />
                    <EDIT_CATEGORY
                      id={obj._id}
                      field="title2"
                      title={obj.title2}
                    />
                  </div>
                ) : (
                  <a href={"/category/" + obj._id} className="row-2">
                    <p>{obj.title}</p>
                    <p>{obj.title2}</p>
                  </a>
                )}

                {auth.editMode ? <DELETE_CATEGORY id={obj._id} /> : ""}
              </div>
            );
          }
          return "";
        })}
      </div>
    </div>
  );
}

import React, {useContext} from "react";
import {CategoryContext} from "../../context/catCTX";
import API_CATEGORIES from "../../API/API_CATEGORIES";
function DELETE_CATEGORY(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(CategoryContext);
  return (
    <button
      onClick={async () => {
        //Add custom alert (own comp)
        if (window.confirm("Are you sure you want to delete this?")) {
          if (await API_CATEGORIES.remove(props.id)) {
            dispatch({
              type: "remove",
              payload: props.id
            });
          } else {
            alert("Deletion failed");
          }
        }
      }}
    >
      Delete
    </button>
  );
}

export default DELETE_CATEGORY;

import React, { useContext, useState } from "react";
import { hiddenCTX } from "../../../../../context/hiddenCTX";
import { AuthContext } from "../../../../../context/authContext";

import API_SUB from "../../../../../API/API_SUB";

import Video from "../../Videos/VideoPlayer";
import Editor from "../../Editor";
import FileUploader from "./fileUploader";

import Sidemenu from "../../../Sidemenu/Sidemenu";

import "./hidden.scss";

function Hidden(props) {
  const [data, dispatch] = useContext(hiddenCTX);
  // eslint-disable-next-line no-unused-vars
  const [auth, change] = useContext(AuthContext);
  const [text, setText] = useState("");

  return (
    <>
      <Sidemenu id={props.id} />
      <div className="video-cont-cont">
        <div className="video-cont">
          <a className="back" href={props.backID}>
            Back
          </a>
          <div className="player-cont">
            {data.video ? <Video url={data.video} /> : ""}
            {auth.editMode ? (
              data.video ? (
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this video?"
                      )
                    ) {
                      const res = API_SUB.remove(data.id, "hiddenVid");
                      if (res) {
                        dispatch({
                          type: "edit",
                          objType: "video",
                          payload: undefined,
                          field: "video "
                        });
                      }
                    }
                  }}
                  className="remove-vid"
                >
                  Delete Vid
                </button>
              ) : (
                <FileUploader />
              )
            ) : (
              ""
            )}
          </div>
          {auth.editMode ? (
            <>
              <Editor
                value={data.videoText}
                onChange={val => {
                  setText(val);
                }}
              />
              <button
                onClick={async () => {
                  const res = await API_SUB.edit(
                    data.id,
                    { videoText: text },
                    "post"
                  );

                  if (res === true) {
                    dispatch({
                      type: "edit",
                      objType: "text",
                      payload: text
                    });
                  }
                }}
              >
                Send
              </button>
            </>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: data.videoText }} />
          )}
        </div>
      </div>
    </>
  );
}

export default Hidden;

import React from "react";
import SidemenuStep from "./SidemenuStep";
import "./sidemenu.scss";
import API_CATEGORIES from "../../../API/API_CATEGORIES";
import logo from "../../../images/logo.png";

export default class Sidemenu extends React.Component {
  // eslint-disable-next-line no-unused-vars
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      stepOpen: undefined,
      data: { cats: [] },
    };
  }
  async componentDidMount() {
    const d = await API_CATEGORIES.get();
    if (d) {
      this.setState({ ...this.state, data: { cats: d } });
    }
  }
  render() {
    let data = this.state.data;

    return (
      <>
        <div className={this.state.open ? "sidemenu smOpen" : "sidemenu"}>
          <a className="logoContLink" href="/">
            <img src={logo} alt="logo" />
          </a>
          <ul>
            {data.cats.map((obj) => {
              let cname = "";
              if (obj._id === this.props.id) {
                cname = "activeCat";
              } else {
              }
              if (obj.type === 0) {
                return (
                  <a
                    className={cname}
                    key={obj._id}
                    href={"/category/" + obj._id}
                  >
                    {obj.title}
                  </a>
                );
              }
              return "";
            })}
            <li className="list-element">
              <p
                className={
                  this.props.activeStep === 1
                    ? "color_module1 activeStep"
                    : "color_module1"
                }
                onClick={() => {
                  this.setState({ ...this.state, stepOpen: 1 });
                }}
              >
                Modul 1
              </p>
              <div
                className={
                  this.state.stepOpen === 1 ? "content open" : "content"
                }
              >
                <SidemenuStep
                  id={this.props.id}
                  setOpen={(val) => {
                    this.setState({ ...this.state, stepOpen: val });
                  }}
                  step={1}
                  data={data.cats.filter(
                    (obj) => obj.type !== 0 && obj.step === 1
                  )}
                />
              </div>
            </li>
            <li className="list-element">
              <p
                className={
                  this.props.activeStep === 2
                    ? "color_module2 activeStep"
                    : "color_module2"
                }
                onClick={() => {
                  this.setState({ ...this.state, stepOpen: 2 });
                }}
              >
                Modul 2
              </p>
              <div
                className={
                  this.state.stepOpen === 2 ? "content open" : "content"
                }
              >
                <SidemenuStep
                  id={this.props.id}
                  setOpen={(val) => {
                    this.setState({ ...this.state, stepOpen: val });
                  }}
                  step={2}
                  data={data.cats.filter(
                    (obj) => obj.type !== 0 && obj.step === 2
                  )}
                />
              </div>
            </li>
            <li className="list-element">
              <p
                className={
                  this.props.activeStep === 3
                    ? "color_module3 activeStep"
                    : "color_module3"
                }
                onClick={() => {
                  this.setState({ ...this.state, stepOpen: 3 });
                }}
              >
                Modul 3
              </p>
              <div
                className={
                  this.state.stepOpen === 3 ? "content open" : "content"
                }
              >
                <SidemenuStep
                  id={this.props.id}
                  setOpen={(val) => {
                    this.setState({ ...this.state, stepOpen: val });
                  }}
                  step={3}
                  data={data.cats.filter(
                    (obj) => obj.type !== 0 && obj.step === 3
                  )}
                />
              </div>
            </li>
          </ul>
          <p
            className={this.state.open ? "smCloseBtn btnOpen" : "smCloseBtn"}
            onClick={() => {
              this.setState({
                open: false,
                stepOpen: 0,
              });
            }}
          >
            Close
          </p>
        </div>
        <div
          onClick={() => {
            this.setState({ ...this.state, open: true });
          }}
          className="smhamburger"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    );
  }
}

import React from "react";
import API_SUB from "../../../../../API/API_SUB";

import Editor from "../../Editor";

import { tpCTX } from "../../../../../context/tpCTX";

export default class ADD_POST extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      text: "",
      tab: false
    };
  }

  render() {
    return (
      <tpCTX.Consumer>
        {CTX => {
          return (
            <div className="add-post">
              <p>Add Post</p>
              <select
                onChange={e => {
                  this.setState({ tab: e.target.value });
                }}
              >
                <option value={undefined}>Select Tab</option>
                {CTX[0].tabs.map(tab => {
                  return (
                    <option key={tab._id} value={tab._id}>
                      {tab.title}
                    </option>
                  );
                })}
              </select>
              {this.state.tab ? (
                <>
                  <input
                    placeholder="post title"
                    value={this.state.title}
                    onChange={e => {
                      this.setState({ title: e.target.value });
                    }}
                  />
                  <Editor
                    onChange={val => {
                      this.setState({ text: val });
                    }}
                  />
                  <button
                    onClick={async () => {
                      if (this.state.title === "") {
                        alert("Missing post title");
                      } else {
                        const dbRes = await API_SUB.add(
                          {
                            title: this.state.title,
                            text: this.state.text,
                            tabID: this.state.tab,
                            objectID: CTX[0].objectID
                          },
                          "post"
                        );

                        CTX[1]({
                          type: "add",
                          objType: "post",
                          tabID: this.state.tab,
                          payload: dbRes
                        });
                      }
                    }}
                  >
                    Send
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          );
        }}
      </tpCTX.Consumer>
    );
  }
}

import React from "react";
import TabPost from "./TabPost/TabPost";
import Videos from "./Videos/Videos";

import TypeSelector from "./TypeSelector";

import Store from "../../../context/tpCTX";
import VideoStore from "../../../context/videoCTX";

export default function Object(props) {
  switch (props.data.type) {
    case -1:
      return "";
      break;

    case 1:
      return (
        <Store
          key={props.id}
          tabs={props.data.tabs}
          objectID={props.id}
          imgLink={props.data.imgLink}
        >
          <TabPost subcatID={props.data.subcatID} />
        </Store>
      );

    case 2:
      return (
        <VideoStore
          key={props.id}
          objectID={props.id}
          videos={props.data.videos}
          subcatID={props.data.subcatID}
        >
          <Videos />
        </VideoStore>
      );

    default:
      return props.editMode ? <TypeSelector data={props.data} /> : "";
  }
}

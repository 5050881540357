import React, {useContext, useState} from "react";
import {tpCTX} from "../../../../../context/tpCTX";
import API_SUB from "../../../../../API/API_SUB";
function EDIT_TAB(props) {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatch] = useContext(tpCTX);
  const [newTitle, setNewTitle] = useState("");
  return (
    <input
      placeholder={props.title}
      value={newTitle}
      onChange={e => {
        setNewTitle(e.target.value);
      }}
      onKeyDown={async e => {
        if (e.keyCode === 13) {
          if (newTitle !== "") {
            const req = await API_SUB.edit(props.id, {title: newTitle}, "tab");

            if (req === true) {
              dispatch({
                type: "edit",
                objType: "tab",
                id: props.id,
                payload: newTitle
              });
              setNewTitle("");
            }
          } else {
            alert("Missing title");
          }
        }
      }}
    />
  );
}

export default EDIT_TAB;

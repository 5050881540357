import React, { useState, useContext } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { hiddenCTX } from "../../../../../context/hiddenCTX";
import API_SUB from "../../../../../API/API_SUB";
import logo from "../../../../../images/logo.png";

const cookie = new Cookies();

const url = process.env.REACT_APP_URL;

function FileUploader() {
  const [file, setFile] = useState();
  const [data, dispatch] = useContext(hiddenCTX);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  async function sendFile() {
    const fd = new FormData();

    const config = {
      headers: {
        authorization: cookie.get("token")
      }
    };
    const fName =
      fileName +
      "." +
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);
    fd.append("file", file, fName);
    await axios
      .post(url + "upload", fd, config)
      .then(async res => {
        if (res.data === true) {
          const vres = await API_SUB.edit(data.id, { video: fName }, "post");
          if (vres === true) {
            dispatch({
              type: "edit",
              objType: "video",
              payload: fName
            });
            setLoading(false);
          }
        }
      })
      .catch(err => {
        if (err.response.status === 403) {
          alert("File already exists, try changing name");
        } else {
          alert("Error");
        }
        setLoading(false);
      });
  }

  return (
    <div className="file-uploader">
      <p>Add Video</p>

      <label for="file-upload" className="custom-file-upload">
        {file ? "Set file name: " : "ATTATCH"}
      </label>
      <input
        id="file-upload"
        onChange={event => {
          setFile(event.target.files[0]);
        }}
        type="file"
      />

      {file ? (
        <>
          <input
            type="text"
            value={fileName}
            onChange={e => {
              const reg = /[^A-Za-z0-9_-]/;

              if (!e.target.value.match(reg)) {
                setFileName(e.target.value);
              }
            }}
          />
          {fileName ? (
            <button
              className="add-object"
              onClick={() => {
                setLoading(true);
                sendFile();
              }}
            >
              {loading ? (
                <img alt="Loading" src={logo} className="uploadingIcon" />
              ) : (
                "Upload"
              )}
            </button>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default FileUploader;
